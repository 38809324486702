import { Image } from '~/elements/Image/Image'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
type Props = {
  className?: string
}
export const DeliveryPay = ({ className }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(className, 'flex flex-col items-center lg:items-start')}>
      <p className="mb-6 font-extralight text-primary-black">{t('Payment & Shipping Partners')}</p>
      <ul
        className={clsx(
          'flex max-w-[254px] flex-wrap items-center justify-center gap-4 lg:justify-start',
        )}
      >
        <li title="Mastercard">
          <Image
            src={require('./assets/MasterCard.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="Paypal">
          <Image
            src={require('./assets/Paypal.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="Visa">
          <Image
            src={require('./assets/Visa.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="Apple-pay">
          <Image
            src={require('./assets/ApplePay.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="Amex">
          <Image
            src={require('./assets/Amex.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        {process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_fr' && (
          <li title="Bancaire">
            <Image
              src={require('./assets/Bancaire.svg')}
              placeholder="empty"
              width={38}
              height={27}
              alt="Bancaire"
            />
          </li>
        )}
        <li title="DHL">
          <Image
            src={require('./assets/DHL.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="DPD">
          <Image
            src={require('./assets/DPD.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="UPS">
          <Image
            src={require('./assets/UPS.png')}
            placeholder="empty"
            width={38}
            height={27}
            alt="UPS"
          />
        </li>
        <li title="GEL">
          <Image
            src={require('./assets/GEL.svg')}
            placeholder="empty"
            width={38}
            height={27}
            alt="GEL"
          />
        </li>
      </ul>
    </div>
  )
}
