import { Logo } from 'modules/Header/components/Logo'

import { SocialLinks } from './components/SocialLinks'
import { DeliveryPay } from './components/DeliveryPay'
import { Newsletter } from './components/Newsletter'
import { FooterLinks } from './components/FooterLinks'
import { Copyright } from './components/Copyright'
import { IconBar } from './components/IconBar'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { RteRteLinks, useBanderoleQuery } from '@contentfulTypes'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import KlimabeitragBenutaLogo from './components/assets/Klimabeitrag-benuta.png'
import { Image } from '~/elements/Image/Image'

export const Footer = () => {
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useBanderoleQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const showSustainabilityLogo =
    process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_de' ||
    process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_at' ||
    process.env.NEXT_PUBLIC_STORE_CODE === 'benuta_chde'

  return (
    <footer className="print:hidden">
      <IconBar />
      <div className="bg-grey-light">
        <div className="mx-auto grid max-w-[2080px] gap-10  p-4 grid-areas-footer-mobile md:p-8 lg:grid-cols-[1fr_auto_auto] lg:p-20 lg:grid-areas-footer">
          <FooterLinks className="grid-in-links" />
          <Newsletter className="grid-in-newsletter" />
          <SocialLinks className="grid-in-social" />
          <div className="flex flex-col items-center grid-in-logos lg:items-start">
            <Logo className="mb-10 lg:mb-6" />
            <DeliveryPay />
            {showSustainabilityLogo && (
              <div className="mt-10 max-w-[180px] text-center lg:mt-9 lg:text-start">
                <a
                  href="https://fpm.climatepartner.com/tracking/23655-2312-1001/de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="h-full w-full"
                    src={KlimabeitragBenutaLogo.src}
                    width={KlimabeitragBenutaLogo.width}
                    height={KlimabeitragBenutaLogo.height}
                    alt="Klimabeitrag Benuta Logo"
                    loading="lazy"
                  />
                </a>
              </div>
            )}
          </div>
          <Copyright className="grid-in-copyright" />
          {data?.pageCollection?.items[0]?.footerNote && (
            <RTEParser
              className="text-center font-extralight lg:-mt-4 lg:text-left"
              rteJSONContent={data?.pageCollection?.items[0]?.footerNote?.rte?.json}
              links={data?.pageCollection?.items[0]?.footerNote?.rte?.links as RteRteLinks}
            />
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
