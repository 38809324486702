import { useElementSize } from '@charlietango/hooks'
import { AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion'
import '@reach/accordion/styles.css'
import { animated, useSpring } from '@react-spring/web'
import clsx from 'clsx'
import { ReactNode, useRef } from 'react'
import { Headline } from '~/elements/Headline/Headline'
import { Image } from '~/elements/Image/Image'

export type ITProps = {
  itemHeading: ReactNode
  itemText?: string
  children: React.ReactNode
  className?: string
  classNameChevron?: string
  classNameButton?: string
  classNameChevronText?: string
  classNameButtonTextContainer?: string
  classNameHeadline?: string
  index?: number
}

export const CustomAccordionItem = ({
  itemHeading,
  itemText,
  children,
  className,
  classNameChevron,
  classNameButton,
  classNameChevronText,
  classNameButtonTextContainer,
  classNameHeadline,
  index,
}: ITProps) => {
  const AnimatedItem = animated(AccordionItem)
  const [panelRef, { height: panelHeight }] = useElementSize()
  const accItem = useRef<HTMLDivElement>(null)

  const expand = useSpring({
    height: !!panelHeight ? Math.floor(panelHeight + 16) : panelHeight,
  })

  return (
    <AnimatedItem className={`overflow-hidden ${className}`} ref={accItem}>
      <AccordionButton
        className={clsx(
          `flex w-full items-center justify-between border-t  border-grey-border py-4`,
          `focus-visible:bg-grey-light focus-visible:bg-opacity-70 focus-visible:outline-none`,
          index === 0 && '!border-t-0',
          classNameButton,
        )}
      >
        <div
          className={clsx(
            'mb-0 flex w-[90%] justify-between lgx:px-4',
            classNameButtonTextContainer,
          )}
        >
          <Headline
            type="h3"
            styleAs="p"
            className={clsx(itemText ? 'max-w-[60%]' : 'max-w-[100%]', classNameHeadline)}
          >
            {itemHeading}
          </Headline>
          <p className={clsx('max-w-[40%] truncate text-grey-dark', classNameChevronText)}>
            {itemText}
          </p>
        </div>
        <div
          className={clsx(
            'mr-2 flex transform items-center justify-center lg:mr-4 lg:h-6 lg:w-6 2xl:h-7 2xl:w-7',
            classNameChevron,
          )}
        >
          <Image
            src="/images/Icons/chevron-down.svg"
            width={20}
            height={20}
            placeholder="empty"
            className={clsx(
              !!panelHeight && 'rotate-180',
              'transition-all duration-200 ease-in-out',
            )}
            alt="chevron-down-icon"
          />
        </div>
      </AccordionButton>
      <animated.div style={expand}>
        <div ref={panelRef} className="text-15-22-sg font-extralight">
          <AccordionPanel>{children}</AccordionPanel>
        </div>
      </animated.div>
    </AnimatedItem>
  )
}
