import { Icon } from 'elements/Icon/Icon'
import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { useNavigationQuery } from '@contentfulTypes'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
type Props = {
  className?: string
}
export const Copyright = ({ className }: Props) => {
  const { t } = useTranslation()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  return (
    <ul
      className={clsx(
        'grid grid-cols-[auto_auto_auto] justify-center justify-items-center gap-x-5 gap-y-4 font-extralight md:grid-cols-[auto_auto_auto_auto] md:justify-start md:justify-items-start md:gap-x-10',
        className,
      )}
    >
      <li className="col-span-3 md:col-span-1 md:whitespace-nowrap">
        {t('Copyright')} &copy; {new Date().getFullYear()} {t('Benuta LLC')}
      </li>
      {data?.pageCollection?.items?.[0]?.footerMetaLinksCollection?.items?.map((item, i) => (
        <li key={item?.sys?.id || i} className="col-span-3 xs:col-span-1">
          <Link
            href={item?.linkInternalUrl as string}
            title={item?.title as string}
            className="whitespace-nowrap"
          >
            {item?.ctaText}
          </Link>
        </li>
      ))}
    </ul>
  )
}
