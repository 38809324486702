import { useNavigationQuery } from '@contentfulTypes'
import clsx from 'clsx'
import { Icon } from 'elements/Icon/Icon'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { NewsletterForm } from '~/modules/NewsletterModule/NewsletterForm'

type Props = {
  className?: string
}

export const Newsletter = ({ className }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const headline = data?.pageCollection?.items?.[0]?.footerNewsletterText?.headline
  const list = data?.pageCollection?.items?.[0]?.footerNewsletterText?.list

  return (
    <div className={clsx('w-full min-w-[200px]', className)}>
      <p className="mb-4 lg:mb-6 lg:text-h3-mobile">{headline}</p>
      <ul className="grid gap-2 font-extralight">
        {list?.map((item) => (
          <li key={item} className="flex items-center gap-4">
            <Icon name="checkmark" />
            <span>{item}</span>
          </li>
        ))}
      </ul>
      <NewsletterForm noMessage hasDesktopPadding={false} isFooter={true} />
    </div>
  )
}
